<template>
  <div class="whole">
    <Nav></Nav>
    <div class="catalog-whole desktop-present">
      <div class="catalogtitle">
        <div class="icon"></div>
        首页 >> {{ catalogTitle }}
      </div>
      <div class="catalog-con">
        <div class="catalog-list" v-for="p in newsList" :key="p.id">
          <div class="catalog-title">
            <div class="title-icon"></div>
            <div class="title-left" @click="onGoCnews(p.id)">{{ p.title }}</div>
            <!-- <div class="title-right" @click="onGoCnews(p.id)">更多>></div> -->
          </div>
          <div class="catalog-content" v-if="p.postType == 'PIC'">
            <div
              class="catalog-detail"
              v-for="k in p.posts"
              :key="'nav_' + k.postId"
            >
              <div
                class="img"
                :style="{ backgroundImage: `url(` + k.imgURL + `)` }"
                @click="onLookPost(k.postId)"
              ></div>
              <div class="title" @click="onLookPost(k.postId)">
                {{ k.title }}
              </div>
            </div>
          </div>
          <div class="catalog-content" v-else>
            <!-- <video
              :src="p.videoURL"
              :poster="p.posterURL"
              controls
              loop
              width="1200"
              height="523"
              @click="onLookPost(p.id)"
            ></video> -->
            <div
              class="video-item"
              v-for="post in p.posts"
              :key="post.postId"
              @click="onLookPost(post.postId)"
            >
              <video
                :src="post.videoURL"
                :poster="post.posterURL"
                :ref="'video_' + post.postId"
                @mouseenter="onVideoEnter('video_' + post.postId)"
                @mouseleave="onVideoLeave('video_' + post.postId)"
                loop
                :muted="post.mute"
              ></video
              ><i
                class="mute el-icon-headset active"
                v-if="post.mute"
                @mouseenter="onVideoEnter('video_' + post.postId)"
                @mouseleave="onVideoLeave('video_' + post.postId)"
                @click.stop="post.mute = !post.mute"
              ></i>
              <i
                class="mute el-icon-headset"
                v-if="!post.mute"
                @mouseenter="onVideoEnter('video_' + post.postId)"
                @mouseleave="onVideoLeave('video_' + post.postId)"
                @click.stop="post.mute = !post.mute"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="catalog-whole mobile-present">
      <div class="catalogtitle">
        <div class="icon"></div>
        首页>>{{ catalogTitle }}
      </div>
      <div class="catalog-con">
        <div class="catalog-list" v-for="p in newsList" :key="p.id">
          <div class="catalog-title">
            <div class="title-icon"></div>
            <div class="title-left" @click="onGoCnews(p.id)">{{ p.title }}</div>
            <!-- <div class="title-right" @click="onGoCnews(p.id)">更多>></div> -->
          </div>
          <div class="catalog-content" v-if="p.postType == 'PIC'">
            <div
              class="catalog-detail"
              v-for="k in p.posts"
              :key="'nav_' + k.postId"
            >
              <div
                class="img"
                :style="{ backgroundImage: `url(` + k.imgURL + `)` }"
                @click="onLookPost(k.postId)"
              ></div>
              <div class="title" @click="onLookPost(k.postId)">
                {{ k.title }}
              </div>
            </div>
          </div>
          <div class="catalog-content" v-else>
            <video
              :src="p.videoURL"
              :poster="p.posterURL"
              controls
              loop
              width="1200"
              height="523"
              @click="onLookPost(p.id)"
            ></video>
          </div>
        </div>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import RD from "@/api/RD";
import Nav from "@/components/nav.vue";
import Bottom from "@/components/bottom.vue";

export default {
  components: { Nav, Bottom },
  data: function () {
    return {
      newsList: [], // 新闻图片的所有
      picList: [],
      videoList: [],
      catalogTitle: "",
    };
  },
  created() {
    this.reload();
  },
  beforeRouteUpdate(to, from, next) {
    if (to.fullPath != from.fullPath) {
      next();
      this.reload();
    }
  },
  methods: {
    reload() {
      var thiz = this;
      console.log("quet", thiz.$route.query);
      thiz.catalogTitle = thiz.$route.query.title;
      if (thiz.$route.query.title == "视频") {
        thiz.newsList = thiz.videoList;
      } else {
        thiz.newsList = thiz.picList;
      }

      RD.index()
        .catalogByName(thiz.catalogTitle)
        .then((data) => {
          console.log("catalogdata", data);
          if (data.length == 0) {
            thiz.$message("当前栏目无稿件");
          }
          data.forEach((m) => {
            if (
              thiz.catalogTitle == "今日读图" ||
              thiz.catalogTitle == "精品图库" ||
              thiz.catalogTitle == "专题活动"
            ) {
              m.postType = "PIC";
            } else if (thiz.catalogTitle == "视频") {
              m.postType = "VIDEO";
            }
          });
          data.forEach((i, index) => {
            console.log("opp", i.posts);

            i.posts.forEach((k) => {
              k.posterURL = RD.video().id(k.postId).poster_URL();
              k.videoURL = RD.video().id(k.postId).svideo_URL();
              k.mute = true;
              if (k.frontPicId) {
                k.imgURL = RD.pic().id(k.frontPicId).mImage_URL();
              } else {
                return false;
              }

              console.log("mx", k.videoURL);
            });
            var indexOrder = index + 1;
            if (indexOrder % 3 == 1) {
              i.typeOne = true;
              i.typeTwo = false;
              i.typeThree = false;
            } else if (indexOrder % 3 == 2) {
              i.typeTwo = true;
              i.typeOne = false;
              i.typeThree = false;
            } else if (indexOrder % 3 == 0) {
              i.typeThree = true;
              i.typeTwo = false;
              i.typeOne = false;
            }
          });

          thiz.newsList = data;
        });
    },
    // 更多
    onGoCnews(navId) {
      this.$router.push({
        path: "/fixTopic.html",
        query: { id: navId },
      });
    },
    onLookPost(postId) {
      this.$router.push({
        path: "/post.html",
        query: { id: postId },
      });
    },
    onVideoEnter(ref) {
      this.$refs[ref][0].play();
    },
    onVideoLeave(ref) {
      this.$refs[ref][0].pause();
    },
  },
};
</script>

<style scoped lang="less">
.catalog-whole.desktop-present {
  height: auto;
  background-color: #fff;
  .catalogtitle {
    height: 4rem;
    text-align: left;
    width: 120rem;
    margin: 0 auto;
    font-size: 18px;
    padding-top: 1.5rem;
    display: flex;
    border-bottom: 0.1rem solid #bfbfbf;
    .icon {
      width: 2rem;
      height: 2rem;
      background: no-repeat url(../assets/loc.png);
      background-size: cover;
      margin-right: 1rem;
    }
  }
  .catalog-con {
    width: 120rem;
    margin: 3rem auto;
    height: 100%;
    .catalog-list {
      height: auto;
      width: 100%;
      margin-bottom: 3rem;
      background-color: #fff;

      .catalog-title {
        height: 6rem;
        font-size: 3rem;
        line-height: 6rem;
        text-align: left;
        // font-weight: bold;
        position: relative;
        background-color: #e6e6e8;
        display: flex;
        .title-icon {
          height: 6rem;
          width: 10px;
          // background-color: #ff8d00;
          background: rgba(215, 20, 12, 0.75);
        }
        .title-left {
          position: absolute;
          left: 3rem;
          cursor: pointer;
        }
        .title-right {
          position: absolute;
          right: 3.4rem;
          top: 0rem;
          width: 6.7rem;
          cursor: pointer;
          color: #9b9b9b;
          .el-icon-right {
            font-size: 2.8rem;
          }
        }
      }
      .catalog-content {
        position: relative;
        margin-top: 10px;
        height: auto;
        display: flex;
        flex-wrap: wrap;
        overflow: hidden;
        justify-content: space-between;
        gap: 15px;
        .catalog-detail {
          height: 214px;
          width: 28.8rem;
          position: relative;
          .img {
            height: 100%;
            width: 100%;
            background: no-repeat 50% / cover;
          }
          .title {
            position: absolute;
            bottom: 0px;
            background-color: #cac7ca;
            width: 100%;
            height: 40px;
            line-height: 40px;
            text-align: center;
            font-size: 20px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            opacity: 0.8;
          }
        }
        .catalog-detail:nth-child(1n + 5) {
          display: none;
        }
        // video {
        //   object-fit: contain;
        //   width: 100%;
        //   height: 100%;
        //   width: 300px;
        //   height: 213px;
        // }
        .video-item {
          width: 28.7rem;
          height: 22rem;
          position: relative;
          video {
            width: 100%;
            height: 100%;
            background-color: #ccc;
            cursor: pointer;
          }
          .mute {
            display: block;
            position: absolute;
            right: 2rem;
            top: 2rem;
            font-size: 2rem;
            height: 3rem;
            width: 3rem;
            border: 2px solid white;
            border-radius: 2rem;
            line-height: 3rem;
            color: white;
          }
          .mute.active {
            color: red;
          }
        }
      }
      .fixtopOne {
        .catalog-detail {
          overflow: hidden;
          .img {
            cursor: pointer;
            transition: all 200ms ease-in-out;
          }
          .title {
            height: 4.3rem;
            line-height: 4.3rem;
            transition: all 200ms ease-in-out;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.3);
            position: absolute;
            bottom: 0rem;
            left: 0rem;
            color: #fff;
            cursor: pointer;
          }
        }
        .catalog-detail:nth-child(1) {
          width: 59.6rem;
          height: 44.1rem;
          position: absolute;
          top: 0rem;
          left: 0rem;
        }
        .catalog-detail:nth-child(2) {
          width: 29.3rem;
          height: 21.6rem;
          position: absolute;
          top: 0rem;
          left: 60.5rem;
        }
        .catalog-detail:nth-child(3) {
          width: 29.3rem;
          height: 21.6rem;
          position: absolute;
          top: 0rem;
          left: 90.8rem;
        }
        .catalog-detail:nth-child(4) {
          width: 29.3rem;
          height: 21.6rem;
          position: absolute;
          top: 22.5rem;
          left: 60.5rem;
        }
        .catalog-detail:nth-child(5) {
          width: 29.3rem;
          height: 21.6rem;
          position: absolute;
          top: 22.5rem;
          left: 90.8rem;
        }
        .catalog-detail:nth-child(6),
        .catalog-detail:nth-child(7),
        .catalog-detail:nth-child(8) {
          width: 27.6rem;
          height: 20rem;
          position: absolute;
          top: 22.5rem;
          left: 192.4rem;
        }
      }
      .fixtopTwo {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .catalog-detail {
          width: 29.3rem;
          height: 21.4rem;
          position: relative;
          overflow: hidden;
          .img {
            cursor: pointer;
            transition: all 200ms ease-in-out;
          }
          .title {
            height: 4.3rem;
            transition: all 200ms ease-in-out;
            line-height: 4.3rem;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.3);
            position: absolute;
            bottom: 0rem;
            left: 0rem;
            color: #fff;
            cursor: pointer;
          }
        }
      }
      .fixtopThree {
        .catalog-detail {
          overflow: hidden;
          .img {
            cursor: pointer;
            transition: all 200ms ease-in-out;
          }
          .title {
            height: 4.3rem;
            line-height: 4.3rem;
            transition: all 200ms ease-in-out;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.3);
            position: absolute;
            bottom: 0rem;
            left: 0rem;
            color: #fff;
            cursor: pointer;
          }
        }
        .catalog-detail:nth-child(1) {
          width: 29.3rem;
          height: 21.6rem;
          position: absolute;
          top: 0rem;
          left: 0rem;
        }
        .catalog-detail:nth-child(2) {
          width: 29.3rem;
          height: 21.6rem;
          position: absolute;
          top: 0rem;
          left: 30.3rem;
        }
        .catalog-detail:nth-child(3) {
          width: 29.3rem;
          height: 21.6rem;
          position: absolute;
          top: 22.6rem;
          left: 0rem;
        }
        .catalog-detail:nth-child(4) {
          width: 29.3rem;
          height: 21.6rem;
          position: absolute;
          top: 22.6rem;
          left: 30.3rem;
        }
        .catalog-detail:nth-child(5) {
          width: 59.6rem;
          height: 44.1rem;
          position: absolute;
          top: 0rem;
          left: 60.6rem;
        }
        .catalog-detail:nth-child(6),
        .catalog-detail:nth-child(7),
        .catalog-detail:nth-child(8) {
          width: 57.1rem;
          height: 45rem;
          position: absolute;
          top: 0rem;
          left: 162.8rem;
        }
      }
    }
  }
}
.catalog-whole.mobile-present {
  height: auto;
  background-color: #fff;
  .catalogtitle {
    height: 4rem;
    text-align: left;
    width: 750px;
    margin: 0 auto;
    font-size: 18px;
    padding-top: 1.5rem;
    display: flex;
    border-bottom: 0.1rem solid #bfbfbf;
    .icon {
      width: 2rem;
      height: 2rem;
      background: no-repeat url(../assets/loc.png);
      background-size: cover;
      margin-right: 1rem;
    }
  }
  .catalog-con {
    width: 750px;
    margin: 3rem auto;
    height: 100%;
    .catalog-list {
      height: auto;
      width: 100%;
      margin-bottom: 3rem;
      background-color: #fff;

      .catalog-title {
        height: 6rem;
        font-size: 3rem;
        line-height: 6rem;
        text-align: left;
        font-weight: bold;
        position: relative;
        background-color: #e6e6e8;
        display: flex;
        .title-icon {
          height: 6rem;
          width: 10px;
          background-color: #ff8d00;
        }
        .title-left {
          position: absolute;
          left: 5rem;
          width: 20rem;
          cursor: pointer;
        }
        .title-right {
          position: absolute;
          right: 3.4rem;
          top: 0rem;
          width: 6.7rem;
          cursor: pointer;
          color: #9b9b9b;
          .el-icon-right {
            font-size: 2.8rem;
          }
        }
      }
      .catalog-content {
        position: relative;
        margin-top: 10px;
        height: auto;
        display: flex;
        gap: 15px;
        .catalog-detail {
          height: 214px;
          width: 29.7rem;
          position: relative;
          .img {
            height: 100%;
            width: 100%;
            background: no-repeat 50% / cover;
          }
          .title {
            position: absolute;
            bottom: 0px;
            background-color: #cac7ca;
            width: 100%;
            height: 40px;
            line-height: 40px;
            text-align: center;
            font-size: 20px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            opacity: 0.8;
          }
        }
        .catalog-detail:nth-child(1n + 5) {
          display: none;
        }
        video {
          object-fit: contain;
          width: 100%;
          height: 100%;
          width: 300px;
          height: 213px;
        }
      }
      .fixtopOne {
        .catalog-detail {
          overflow: hidden;
          .img {
            cursor: pointer;
            transition: all 200ms ease-in-out;
          }
          .title {
            height: 4.3rem;
            line-height: 4.3rem;
            transition: all 200ms ease-in-out;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.3);
            position: absolute;
            bottom: 0rem;
            left: 0rem;
            color: #fff;
            cursor: pointer;
          }
        }
        .catalog-detail:nth-child(1) {
          width: 59.6rem;
          height: 44.1rem;
          position: absolute;
          top: 0rem;
          left: 0rem;
        }
        .catalog-detail:nth-child(2) {
          width: 29.3rem;
          height: 21.6rem;
          position: absolute;
          top: 0rem;
          left: 60.5rem;
        }
        .catalog-detail:nth-child(3) {
          width: 29.3rem;
          height: 21.6rem;
          position: absolute;
          top: 0rem;
          left: 90.8rem;
        }
        .catalog-detail:nth-child(4) {
          width: 29.3rem;
          height: 21.6rem;
          position: absolute;
          top: 22.5rem;
          left: 60.5rem;
        }
        .catalog-detail:nth-child(5) {
          width: 29.3rem;
          height: 21.6rem;
          position: absolute;
          top: 22.5rem;
          left: 90.8rem;
        }
        .catalog-detail:nth-child(6),
        .catalog-detail:nth-child(7),
        .catalog-detail:nth-child(8) {
          width: 27.6rem;
          height: 20rem;
          position: absolute;
          top: 22.5rem;
          left: 192.4rem;
        }
      }
      .fixtopTwo {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .catalog-detail {
          width: 29.3rem;
          height: 21.4rem;
          position: relative;
          overflow: hidden;
          .img {
            cursor: pointer;
            transition: all 200ms ease-in-out;
          }
          .title {
            height: 4.3rem;
            transition: all 200ms ease-in-out;
            line-height: 4.3rem;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.3);
            position: absolute;
            bottom: 0rem;
            left: 0rem;
            color: #fff;
            cursor: pointer;
          }
        }
      }
      .fixtopThree {
        .catalog-detail {
          overflow: hidden;
          .img {
            cursor: pointer;
            transition: all 200ms ease-in-out;
          }
          .title {
            height: 4.3rem;
            line-height: 4.3rem;
            transition: all 200ms ease-in-out;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.3);
            position: absolute;
            bottom: 0rem;
            left: 0rem;
            color: #fff;
            cursor: pointer;
          }
        }
        .catalog-detail:nth-child(1) {
          width: 29.3rem;
          height: 21.6rem;
          position: absolute;
          top: 0rem;
          left: 0rem;
        }
        .catalog-detail:nth-child(2) {
          width: 29.3rem;
          height: 21.6rem;
          position: absolute;
          top: 0rem;
          left: 30.3rem;
        }
        .catalog-detail:nth-child(3) {
          width: 29.3rem;
          height: 21.6rem;
          position: absolute;
          top: 22.6rem;
          left: 0rem;
        }
        .catalog-detail:nth-child(4) {
          width: 29.3rem;
          height: 21.6rem;
          position: absolute;
          top: 22.6rem;
          left: 30.3rem;
        }
        .catalog-detail:nth-child(5) {
          width: 59.6rem;
          height: 44.1rem;
          position: absolute;
          top: 0rem;
          left: 60.6rem;
        }
        .catalog-detail:nth-child(6),
        .catalog-detail:nth-child(7),
        .catalog-detail:nth-child(8) {
          width: 57.1rem;
          height: 45rem;
          position: absolute;
          top: 0rem;
          left: 162.8rem;
        }
      }
    }
  }
}
// .catalog-detail:hover {
//   .img {
//     transform: scale(1.05);
//   }
//   .title {
//     opacity: 1;
//     font-size: 1.6rem;
//   }
// }
</style>
